@use 'theme/vars.scss';
@use 'theme/fonts.scss';
@use 'theme/drawflow.scss';
@use 'theme/calendar.scss';
@use 'theme/ag-grid.scss';
@use 'ngx-toastr/toastr';
@use '@angular/material/prebuilt-themes/deeppurple-amber.css';
@use '@ctrl/ngx-emoji-mart/picker';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-base;
    }

    *:focus {
        @apply caret-twMidDarkPurple outline-twElectricPurple;
    }

    li {
        @apply list-none;
    }
}

@layer components {
    .page-title {
        @apply whitespace-nowrap text-2xl font-medium capitalize;
    }

    .card-wrapper {
        @apply rounded-md border border-twGrayLightest bg-twComponentBackground shadow-insights;
    }

    .pop-menu {
        @apply rounded-md border border-twGrayLightest bg-twComponentBackground shadow-popMenu;
    }

    /* Dropdown */

    .dropdown-menu {
        @apply mt-1 flex flex-col overflow-y-auto rounded bg-twComponentBackground shadow-popMenu;
    }

    .dropdown-opt-single {
        @apply px-4 py-2 text-start text-twGrayDark hover:bg-twElectricPurple hover:text-twTextInverse;
    }

    .dropdown-opt-single-sm {
        @apply py-1 text-twGrayDark hover:bg-twElectricPurple hover:text-twTextInverse;
    }

    .dropdown-opt-single-disabled {
        @apply py-2 text-start text-twGray hover:bg-twLightPurple hover:text-twMidPurple;
    }

    /* Form inputs */

    .twinny-form {
        &-field {
            @apply relative flex flex-grow;

            &__separator {
                @apply block w-full border-b border-twGrayLight;
            }
        }

        &-input {
            @apply flex-grow rounded-sm border px-4 py-1.5;

            /* For manual addition of error class */
            &__error {
                @apply border-twErrorRed text-twErrorRed;
            }

            &:disabled {
                @apply bg-twGrayLightest text-twGrayDark;
            }

            &:has(:disabled) {
                @apply bg-twGrayLightest text-twGrayDark;
            }
        }

        /* For applying error styles automatically */
        &-input.ng-invalid.ng-dirty {
            @apply border-twErrorRed;
        }

        &-error-text {
            @apply absolute bottom-0 translate-y-full text-xs text-twErrorRed;
        }

        &-footer {
            @apply pt-12;
        }
    }

    /* Dialog */
    .twinny-dialog {
        //absolute inset-0 z-10 flex items-center justify-center
        @apply card-wrapper absolute z-10 m-0  h-full w-full items-center justify-center;
        //create backdrop efect with after and before pseudo elements
        /*    &::before,
        &::after {
            @apply fixed inset-0 bg-twGrayLightest;
            content: '';
            z-index: 2;
        } */
        /*         &::backdrop { TODO: now not working but its standard way to create backdrop effect
            @apply backdrop-blur;
        } */
        &__content {
            @apply relative h-fit w-fit;
        }
        &[open='true'] {
            .twinny-dialog {
                @apply card-wrapper absolute inset-0 flex items-center justify-center;
            }

            &.twinny-dialog::backdrop {
                @apply backdrop-blur;
            }
        }
        &[open='false'] {
            @apply hidden;
        }
    }

    /* Variables interpolated */
    .twinny-variable {
        @apply text-twElectricPurple;
    }

    /* Labels */

    .department-badge {
        @apply truncate rounded border border-zinc-600 bg-zinc-300 px-1 text-sm leading-none text-zinc-500;
    }
}

@layer utilities {
    .bg-twinny {
        background: linear-gradient(
            90deg,
            #ae3ac8 0%,
            #b43cc7 10.42%,
            #bd43bf 20%,
            #c84ab9 28.65%,
            #ce50b4 40%,
            #d855ae 47.92%,
            #d759a3 54.17%,
            #d95c9a 65.1%,
            #d9628b 72.4%,
            #da6680 80%,
            #db6a79 89.06%,
            #da6f69 100%
        );
    }
}

:root {
    /* TOOLTIP */
    .mat-mdc-tooltip-surface {
        @apply rounded-[20px] bg-twLightPurple px-4 py-2.5 text-sm text-twTextPrimary shadow-insights;
    }
}

/****************************************************
* DEFAULTS
*****************************************************/

:root {
    --mdc-plain-tooltip-container-color: transparent;
    --mdc-plain-tooltip-supporting-text-color: inherit;
}

html {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px;
}

body {
    height: 100%;
    background-color: #ffffff;
    overflow: auto;
}

app-root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/****************************************************
* TOASTR
*****************************************************/

.toast-container .ngx-toastr {
    padding: 0;
    background: transparent;
    box-shadow: none;
    overflow: visible;
}

.toast-container .ngx-toastr:hover {
    box-shadow: none;
}

/****************************************************
* INPUTS
*****************************************************/

input,
textarea {
    background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
}

/* INPUT CHECKBOXES */

/* Hide the browser's default checkbox */
.input-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    min-height: 13px;
    min-width: 13px;
    border: 1px solid vars.$tw-gray-light;
    border-radius: 2px;
}

.checkmark.checked,
.checkmark.mid {
    position: relative;
    background-color: vars.$tw-electric-purple;
    border-color: vars.$tw-electric-purple;
}

.checkmark.check-disabled {
    background-color: vars.$tw-gray-light;
    border-color: vars.$tw-gray-light;
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.checkmark.checked:after,
.checkmark.mid:after {
    display: block;
}

.checkmark.checked:after {
    left: 3.5px;
    top: 0.5px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark.mid:after {
    left: 2px;
    top: 5px;
    width: 7px;
    height: 0px;
    border: solid white;
    border-width: 1px 0 0 0;
    border-radius: 2px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.checkmark.invalid {
    border-color: vars.$tw-error-red;
}

/* On mouse-over, add a grey background color */
.input-wrapper:hover input ~ .checkmark {
    border-color: vars.$tw-electric-purple;
}

/****************************************************
 *  CUSTOM SCROLLBAR
 ****************************************************/

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
}

::-webkit-scrollbar-track-piece:start {
    background: transparent;
}

::-webkit-scrollbar-track-piece:end {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #00000030;
    border-radius: 10rem;
    border: 1px solid #fff;
}

/****************************************************
*   DATEPICKER
****************************************************/

/* Datepicker range */
.mat-mdc-form-field {
    max-width: 0 !important;
}
// hide inputs
.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    width: 0 !important;
    overflow: hidden !important;
}

// hide error message container
.mat-mdc-form-field-subscript-wrapper {
    display: none;
}

// paddings
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding: 0;
}
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
    padding: 0 !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    border: none !important;
}

// background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}
// background on hover
.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}
// background when disabled
.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent !important;
}

// border bottom
.mdc-line-ripple::before,
.mdc-line-ripple::after {
    content: '' !important;
    border: none !important;
}

.mat-mdc-form-field-infix {
    display: none;
}

/* Datepicker: single date */
//
.mat-mdc-button-touch-target {
    max-width: 0;
}

/*****************************************************
*   OLD TABLE
******************************************************/

.ngx-datatable {
    font-family: ui-sans-serif, system-ui;
    font-size: 14px !important;
    height: 632px;
}

// HEADER

.datatable-header {
    background-color: vars.$tw-gray-lightest;
}

.datatable-header-inner {
    height: 100%;
}

.custom-header-cell {
    display: flex;
    align-items: center;
    height: 100%;
}

.main-column {
    display: flex !important;
}

.main-column::before {
    content: '';
    padding-left: 100px;
}

.datatable-header-cell-template-wrap {
    display: flex;
    align-items: center;
    height: 100% !important;
    width: 100%;
    margin-top: -1px;
    padding: 0 0 0 10px;
}

.resize-handle {
    visibility: visible !important;
    border-right: 1px solid vars.$tw-gray-lighter;
}

datatable-header .datatable-row-center .datatable-header-cell:nth-last-child(1) .resize-handle {
    margin-right: 32px;
}

.datatable-header-cell-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100% !important;
    width: 100%;
    user-select: none;
}

// BODY

.datatable-body {
    font-size: 13px;
    padding: 6px 0 0 0;
    z-index: 0;
}

// row division lines
.datatable-body-row {
    border-top: 1px solid vars.$tw-gray-lighter;
}
.datatable-row-wrapper:first-child .datatable-body-row {
    border-top: none;
}

.expanded-row {
    border-bottom: none;
}
.row-expansion {
    border-top: none;
}

/* Special stateful items */

.selectable .datatable-body-row.active .ext-link-icon {
    color: vars.$tw-electric-purple;
}

.expand-row-icon {
    color: vars.$tw-gray;
}
.selectable .datatable-body-row.active .expand-row-icon {
    color: vars.$tw-electric-purple;
}
/*  */

// hover and selected row color
.datatable-body-row:hover .datatable-body-cell {
    background-color: vars.$tw-gray-lighter;
}
.selectable .datatable-body-row.active .datatable-body-cell {
    background-color: vars.$tw-light-purple;
    color: vars.$tw-electric-purple;
}

// row cells
.datatable-body-cell {
    padding: 6px 0 6px 12px !important;
    display: flex !important;
    align-items: center;
    white-space: nowrap;
}

// rows padding left
.datatable-body-cell:first-child {
    // padding-left: 30px !important;
}

.datatable-body-cell-label {
    width: 100%;
}

.number-cell {
    padding-right: 20%;
    text-align: end;
}

// ICONS

//  color set with filter property !
.sort-arrow-down {
    content: url('data:image/svg+xml; utf8, <svg width="8" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.919 0.18H1.07902C0.119016 0.18 -0.360984 1.34 0.319016 2.02L5.49902 7.2C6.32902 8.03 7.67902 8.03 8.50902 7.2L10.479 5.23L13.689 2.02C14.359 1.34 13.879 0.18 12.919 0.18Z" fill="black"/></svg>') !important;
    filter: invert(51%) sepia(7%) saturate(302%) hue-rotate(201deg) brightness(83%) contrast(89%);
    margin-left: 8px;
}

.rotate {
    transform: rotate(180deg);
}

//  -------------------------------------------
