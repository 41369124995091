.fc-popover {
    max-width: 300px;
}

.fc-more-link {
    @apply mt-1 rounded-xl bg-zinc-300 px-2 py-1 text-zinc-500 !important;
}

.fc-timeGridWeek-view,
.fc-timeGridDay-view {
    thead {
        @apply bg-twLightPurple;
    }
}

.fc-list-day {
    th {
        @apply bg-twLightPurple !important;
    }
}
.fc-day-today {
    @apply bg-zinc-100 !important;
}
