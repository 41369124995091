.ag-root-wrapper {
    @apply rounded-none border-0;
}

.ag-header {
    @apply h-7 border-zinc-300 bg-zinc-100;
}

.ag-cell {
    @apply border-r-zinc-100;
}

.ag-row {
    @apply border-zinc-100;
}
